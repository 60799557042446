import { History } from "@mui/icons-material";
import type { SvgIconProps, TypographyProps } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import { cloneElement, useState } from "react";
import type { ReactElement, ReactNode } from "react";
import type { FieldProps } from "react-admin";

export const FieldItem = ({
  label,
  icon,
  children,
  onIconClick,
  history,
}: {
  label: string;
  icon?: ReactElement;
  onIconClick?: () => void;
  children: ReactElement;
  history?: ReactNode;
}) => {
  const [isHistoryOpened, setHistoryOpened] = useState(false);

  return (
    <Stack direction="row" alignItems="center" spacing={1} marginBottom={1}>
      {icon &&
        cloneElement<SvgIconProps>(icon, {
          htmlColor: onIconClick ? colors.blue[600] : colors.grey[700],
          fontSize: "medium",
          cursor: onIconClick ? "pointer" : undefined,
          onClick: onIconClick,
        })}
      <Divider orientation="vertical" flexItem />
      <Stack direction="column">
        <Typography
          variant="overline"
          lineHeight={1.5}
          fontWeight="bold"
          color={colors.grey[700]}
        >
          {label}{" "}
          {history && (
            <>
              <IconButton
                onClick={() => {
                  setHistoryOpened((v) => !v);
                }}
                sx={{ fontSize: 17, padding: 0 }}
              >
                <History fontSize="inherit" />
              </IconButton>
              <Dialog
                open={isHistoryOpened}
                onClose={() => setHistoryOpened(false)}
                fullWidth
              >
                <DialogTitle>{label} history</DialogTitle>
                <DialogContent>{history}</DialogContent>
              </Dialog>
            </>
          )}
        </Typography>
        <Stack direction="column">
          {cloneElement<TypographyProps & FieldProps>(children, {
            variant: "body1",
            emptyText: "-",
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
