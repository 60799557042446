import HousingIcon from "@mui/icons-material/DoorFront";
import { Box, Grid, Typography } from "@mui/material";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  FunctionField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  UrlField,
  WithRecord,
} from "react-admin";
import FunctionList from "../../../display/FunctionList";
import { InlineLabel } from "../../../layout/InlineLabel";
import { communicationFields } from "../../communications/fields";
import { DataTab } from "../../devices/show/tabs/data";
import { EndUserEditionForm } from "../../end-users/Edit";
import { ContactsField } from "../../end-users/fields/ContactsField";
import { NameField } from "../../end-users/fields/NameField";
import type { EndUser } from "../../end-users/type";
import { InterventionCreate } from "../../intervention/Edit";
import { InterventionSourcingStatusField } from "../../intervention/fields/InterventionSourcingStatusField";
import { InterventionStatusReasonField } from "../../intervention/fields/InterventionStatusReasonField";
import { InterventionStatusField } from "../../intervention/fields/status";
import { InterventionTypeField } from "../../intervention/fields/type";
import { formatAddress } from "../fields/Address";
import { InterventionDevice } from "../fields/Device";
import { PDL } from "../fields/PDL";
import { HousingStatusField } from "../fields/Status";
import type { Housing } from "../types";
import { HousingActions } from "./actions";

const EndUserHousingHistory = ({ housing }: { housing: Housing }) => (
  <FunctionList<EndUser>
    render={(endUser) => {
      return (
        <ArrayField
          source="housingHistory"
          record={{
            housingHistory: endUser.housingHistory.filter(
              (h) => h.housingId === housing.id
            ),
          }}
        >
          <Datagrid bulkActionButtons={false}>
            <DateField source="date" label="Date" />
            <TextField source="type" label="Type" />
          </Datagrid>
        </ArrayField>
      );
    }}
  />
);

export const HousingShow = () => {
  return (
    <Show actions={<HousingActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Housing">
          <SimpleShowLayout>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box display="flex" alignItems="center">
                  <HousingIcon fontSize="large" sx={{ marginRight: 1 }} />
                  <TextField
                    source="unitId"
                    variant="h4"
                    sx={{ marginRight: 1 }}
                  />
                  <HousingStatusField />
                </Box>
                <FunctionField<Housing>
                  render={(record) =>
                    record && (
                      <Typography variant="h6">
                        {formatAddress(record)}
                      </Typography>
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <InlineLabel label="Unit ID">
                  <TextField source="unitId" />
                </InlineLabel>
                <InlineLabel label="Landlord Unit ID">
                  <TextField source="landlordUnitId" emptyText="-" />
                </InlineLabel>
                <PDL />
                <InlineLabel label="Reservation link">
                  <UrlField source="reservationUrl" />
                </InlineLabel>
                <InlineLabel label="Reservation short link (Courrier)">
                  <UrlField source="letterAppointmentUrl" />
                </InlineLabel>
                <InlineLabel label="Reservation short link (Email)">
                  <UrlField source="emailAppointmentUrl" />
                </InlineLabel>
              </Grid>
              <Grid item xs={12} md={4}>
                <ReferenceField
                  reference="residences"
                  source="residenceId"
                  link={false}
                >
                  <Typography variant="h5">
                    Residence <ShowButton />
                  </Typography>
                  <InlineLabel label="ESI">
                    <TextField source="esi" />
                  </InlineLabel>
                  <InlineLabel label="Client">
                    <ReferenceField
                      source="organizationId"
                      reference="organizations"
                      link="show"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </InlineLabel>
                  <InlineLabel label="Address">
                    <TextField source="address" />
                  </InlineLabel>
                  <InlineLabel label="Zip Code">
                    <TextField source="zipCode" />
                  </InlineLabel>
                  <InlineLabel label="City">
                    <TextField source="city" />
                  </InlineLabel>
                  <InlineLabel label="Installer Phone">
                    <TextField source="installerPhone" />
                  </InlineLabel>
                </ReferenceField>
              </Grid>
              <Grid item xs={12}>
                <InlineLabel label="Type">
                  <TextField source="type" emptyText="-" />
                </InlineLabel>
              </Grid>
              <Grid item xs={12}>
                <InlineLabel label="Eligible CEE (+2 years old)">
                  <BooleanField source="isCEEEligible" emptyText="-" />
                </InlineLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField source="comment" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Devices</Typography>
                <ReferenceManyField
                  reference="devices"
                  target="housingId"
                  sort={{ field: "datetime", order: "ASC" }}
                >
                  <Datagrid bulkActionButtons={false} expand={<DataTab />}>
                    <TextField label="Name" source="name" />
                    <ShowButton />
                  </Datagrid>
                </ReferenceManyField>
              </Grid>
              <Grid item xs={12}>
                <ReferenceManyField
                  reference="end-users"
                  target="housingIds"
                  sort={{ field: "datetime", order: "ASC" }}
                >
                  <Typography variant="h6">
                    End Users
                    <WithRecord
                      render={(record) => (
                        <CreateInDialogButton
                          record={{ housingIds: [record.id] }}
                          title="Create a user"
                          maxWidth="lg"
                          ButtonProps={{
                            sx: { marginLeft: 1 },
                            variant: "outlined",
                          }}
                        >
                          <EndUserEditionForm />
                        </CreateInDialogButton>
                      )}
                    />
                  </Typography>
                  <Datagrid bulkActionButtons={false}>
                    <NameField label="Name" />
                    <ContactsField label="Emails" type="emails" />
                    <ContactsField label="Phone Numbers" type="phoneNumbers" />
                    <ShowButton />
                    <EditButton />
                    <DeleteButton redirect={false} />
                  </Datagrid>
                </ReferenceManyField>
              </Grid>
              <Grid item xs={12}>
                <ReferenceManyField
                  reference="interventions"
                  target="housingId"
                  sort={{ field: "datetime", order: "DESC" }}
                >
                  <Typography variant="h6">
                    Interventions
                    <WithRecord
                      render={(record) => (
                        <CreateInDialogButton
                          record={{ housingId: record.id }}
                          title="Create an intervention"
                          maxWidth="lg"
                          ButtonProps={{
                            sx: { marginLeft: 1 },
                            variant: "outlined",
                          }}
                        >
                          <InterventionCreate />
                        </CreateInDialogButton>
                      )}
                    />
                  </Typography>
                  <Datagrid bulkActionButtons={false}>
                    <InterventionTypeField label="Type" />
                    <InterventionStatusField label="Status" />
                    <InterventionSourcingStatusField label="Sourcing Status" />
                    <InterventionStatusReasonField label="Status Reason" />
                    <DateField label="Date" source="datetime" showTime />
                    <InterventionDevice label="Device" />
                    <ReferenceField
                      label="Project"
                      source="projectId"
                      reference="projects"
                      link="show"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <TextField label="Comment" source="comment" />
                    <ShowButton />
                    <DeleteButton redirect={false} />
                  </Datagrid>
                </ReferenceManyField>
              </Grid>
              <Grid xs={12}>
                <ReferenceManyField
                  reference="communications"
                  target="context.housingId"
                  pagination={<Pagination />}
                >
                  <Typography variant="h6">Communications</Typography>
                  <Datagrid bulkActionButtons={false}>
                    {communicationFields.date}
                    {communicationFields.topic}
                    {communicationFields.medium}
                    {communicationFields.status}
                    {communicationFields.project}
                    {communicationFields.resident}
                    {communicationFields.externalId}
                    <ShowButton />
                  </Datagrid>
                </ReferenceManyField>
              </Grid>
            </Grid>
          </SimpleShowLayout>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Absences" path="absences">
          <SimpleShowLayout>
            <ReferenceManyField
              reference="absences"
              target="housingId"
              sort={{ field: "start_date", order: "DESC" }}
              pagination={<Pagination />}
            >
              <Datagrid bulkActionButtons={false}>
                <DateField label="Start Date" source="start_date" showTime />
                <DateField label="End Date" source="end_date" showTime />
                <DateField label="Created At" source="createdAt" showTime />
              </Datagrid>
            </ReferenceManyField>
          </SimpleShowLayout>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
          label="End Users History"
          path="end-users-history"
        >
          <FunctionField<Housing>
            render={(housing) => (
              <ReferenceManyField
                reference="end-users"
                target="housingHistory.housingId"
              >
                <Datagrid
                  bulkActionButtons={false}
                  expand={<EndUserHousingHistory housing={housing} />}
                >
                  <NameField variant="body1" />
                  <ShowButton />
                </Datagrid>
              </ReferenceManyField>
            )}
          />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
