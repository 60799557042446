import BookmarkIcon from "@mui/icons-material/Bookmark";
import ConstructionIcon from "@mui/icons-material/Construction";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import type { StackProps } from "@mui/material";
import { Grid, Stack, Typography } from "@mui/material";
import type { ReactNode } from "react";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  Labeled,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowButton,
  TextField,
  WithRecord,
  useRecordContext,
} from "react-admin";
import type { ReactElement } from "react-markdown/lib/react-markdown";
import { FieldGrid } from "../../display/FieldGrid";
import { FieldItem } from "../../display/FieldItem";
import { EnumField } from "../../fields/EnumField";
import { JsonField } from "../../fields/JsonField";
import { InlineLabel } from "../../layout/InlineLabel";
import {
  ActionsMenu,
  DeleteAction,
  EditAction,
} from "../../layout/actions-menu";
import { DeviceIcon } from "../devices";
import { HousingIcon } from "../housing";
import { MaintenanceProviderIcon } from "../maintenance-provider";
import { ProjectIcon } from "../project";
import { ApplyIntervention } from "./actions/Apply";
import { DocumentsField } from "./fields/Documents";
import { InterventionSourcingStatusField } from "./fields/InterventionSourcingStatusField";
import { InterventionStatusReasonField } from "./fields/InterventionStatusReasonField";
import { KizeoField } from "./fields/kizeo";
import { InterventionStatusField } from "./fields/status";
import { InterventionTypeField } from "./fields/type";
import type { Intervention } from "./types";
import {
  InterventionType,
  interventionSourcingTypeColors,
  interventionSourcingTypeLabels,
} from "./types";

export const InterventionShow = () => {
  return (
    <Show actions={<InterventionActions />}>
      <Grid container spacing={1} padding={2}>
        <Grid item xs={12}>
          <SectionTitle mt={0}>Etat de l'intervention</SectionTitle>
          <FieldGrid>
            <FieldItem label="Type" icon={<ConstructionIcon />}>
              <InterventionTypeField />
            </FieldItem>
            <FieldItem label="Statut" icon={<BookmarkIcon />}>
              <InterventionStatusField />
            </FieldItem>
          </FieldGrid>
        </Grid>

        <Grid item xs={12}>
          <SectionTitle mt={0}>Relations</SectionTitle>
          <FieldGrid>
            <FieldItem label="Housing" icon={<HousingIcon />}>
              <ReferenceField
                reference="housings"
                source="housingId"
                link="show"
              >
                <TextField source="unitId" variant="body1" />
              </ReferenceField>
            </FieldItem>

            <FieldItem label="Project" icon={<ProjectIcon />}>
              <ReferenceField
                reference="projects"
                source="projectId"
                link="show"
              >
                <TextField source="name" variant="body1" />
              </ReferenceField>
            </FieldItem>

            <FieldItem
              label="Maintenance Provider"
              icon={<MaintenanceProviderIcon />}
            >
              <ReferenceField
                source="maintenanceProviderId"
                reference="maintenance-providers"
              >
                <TextField source="name" variant="body1" />
              </ReferenceField>
            </FieldItem>

            <FieldItem label="Device" icon={<DeviceIcon />}>
              <ReferenceField reference="devices" source="deviceId" link="show">
                <TextField source="name" variant="body1" />
              </ReferenceField>
            </FieldItem>

            <IfIsReplacement>
              <FieldItem label="Replaced Device" icon={<DeviceIcon />}>
                <ReferenceField
                  reference="devices"
                  source="replacedDeviceId"
                  link="show"
                >
                  <TextField source="name" variant="body1" />
                </ReferenceField>
              </FieldItem>
            </IfIsReplacement>
          </FieldGrid>
        </Grid>

        <Grid item xs={12}>
          <SectionTitle icon={<InsertInvitationIcon />}>Infos RDV</SectionTitle>
          <InlineLabel label="Intervention créée le">
            <DateField source="createdAt" showTime />
          </InlineLabel>
          <InlineLabel label="Statut du RDV">
            <InterventionSourcingStatusField />
          </InlineLabel>
          <InlineLabel label="Date du RDV">
            <DateField source="plannedSlot.startDate" showTime />
          </InlineLabel>
          <InlineLabel label="Type de RDV">
            <EnumField
              label="Type de RDV"
              source="sourcingType"
              colors={interventionSourcingTypeColors}
              labels={interventionSourcingTypeLabels}
            />
          </InlineLabel>
          <InlineLabel label="Commentaire RDV">
            <TextField source="sourcingComment" />
          </InlineLabel>
          <Typography variant="body1" fontWeight="bold" marginRight={1}>
            Evenements Calendly:
          </Typography>
          <ArrayField
            sort={{ field: "createdAt", order: "DESC" }}
            source="calendlyPlannedEvents"
            label="Calendly Planned Events"
            emptyText="-"
          >
            <Datagrid
              bulkActionButtons={false}
              expand={
                <Stack>
                  <Labeled>
                    <TextField source="eventUuid" label="Event UUID" />
                  </Labeled>
                  <Labeled>
                    <TextField
                      source="inviteeUuid"
                      label="Invitee UUID"
                      emptyText="-"
                    />
                  </Labeled>
                  <WithRecord
                    render={(event) => {
                      if (!event.canceledAt) {
                        return null;
                      }
                      return (
                        <>
                          <Labeled>
                            <TextField
                              source="cancelReason"
                              label="Cancel Reason"
                              emptyText="-"
                            />
                          </Labeled>
                          <Labeled>
                            <TextField
                              source="newEventUuid"
                              label="New Event UUID"
                              emptyText="-"
                            />
                          </Labeled>
                          <Labeled>
                            <TextField
                              source="newInviteeUuid"
                              label="New Invitee UUID"
                              emptyText="-"
                            />
                          </Labeled>
                        </>
                      );
                    }}
                  />
                </Stack>
              }
              size="small"
            >
              <DateField source="startTime" showTime label="Planned Date" />
              <DateField source="createdAt" showTime label="Booked at" />
              <DateField source="canceledAt" showTime label="Canceled at" />
            </Datagrid>
          </ArrayField>
        </Grid>

        <Grid item xs={12}>
          <SectionTitle icon={<ListAltIcon />}>Infos Kizeo</SectionTitle>
          <InlineLabel label="Date effective de l'intervention">
            <DateField source="datetime" showTime />
          </InlineLabel>
          <InlineLabel label="Statut après visite">
            <InterventionStatusReasonField />
          </InlineLabel>
          <InlineLabel label="Kizeo">
            <KizeoField />
          </InlineLabel>
          <InlineLabel label="Commentaire">
            <TextField source="comment" />
          </InlineLabel>
          <InlineLabel label="Raison KO Kizeo">
            <TextField source="sourcingFailureReason" />
          </InlineLabel>
          <InlineLabel label="Contact">
            <TextField source="data.contactTry" />
          </InlineLabel>
          <InlineLabel label="Technicien">
            <TextField source="technician" />
          </InlineLabel>
          <InlineLabel label="Historique Kizeo">
            <TextField source="externalHistory" />
          </InlineLabel>
          <InlineLabel label="Next action">
            <TextField source="nextAction" />
          </InlineLabel>
          <InlineLabel label="Compteur Linky">
            <BooleanField source="data.hasLinky" />
          </InlineLabel>
          <InlineLabel label="Contrat HP/HC">
            <BooleanField source="data.contractType.isHPHC" />
          </InlineLabel>
          <InlineLabel label="Maintenance Needed">
            <BooleanField source="data.maintenanceNeeded" />
          </InlineLabel>
          <InlineLabel label="Maintenance Details">
            <TextField source="data.maintenanceDetails" />
          </InlineLabel>
          <InlineLabel label="Maintenance Emergency">
            <BooleanField source="data.maintenanceIsUrgent" />
          </InlineLabel>
        </Grid>

        <Grid item xs={12}>
          <SectionTitle icon={<PermMediaIcon />}>Documents</SectionTitle>
          <DocumentsField />
        </Grid>

        <Grid item xs={12}>
          <JsonField
            source="data"
            reactJsonOptions={{
              name: "Raw data",
              collapsed: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SectionTitle icon={<HousingIcon />}>
            Interventions associées au même logement
          </SectionTitle>
          <OtherInterventionsList
            reference="housings"
            source="housingId"
            target="housingId"
          />
        </Grid>

        <Grid item xs={12}>
          <WithRecord
            render={(intervention) => {
              if (!intervention.deviceId) {
                return null;
              }
              return (
                <>
                  <SectionTitle icon={<DeviceIcon />}>
                    Interventions associées au même boitier
                  </SectionTitle>
                  <OtherInterventionsList
                    reference="devices"
                    source="deviceId"
                    target="deviceId"
                  />
                </>
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <IfIsReplacement>
            <SectionTitle icon={<DeviceIcon />}>
              Interventions associées au même boitier remplacé
            </SectionTitle>
            <OtherInterventionsList
              reference="devices"
              source="replacedDeviceId"
              target="deviceId"
            />
          </IfIsReplacement>
        </Grid>
      </Grid>
    </Show>
  );
};

const InterventionActions = () => (
  <ActionsMenu>
    <EditAction />
    <ApplyIntervention />
    <DeleteAction />
  </ActionsMenu>
);

const SectionTitle = ({
  icon,
  children,
  ...props
}: {
  icon?: ReactElement;
  children: ReactNode;
} & StackProps) => (
  <>
    <Stack direction="row" alignItems="center" spacing={1} mt={2} {...props}>
      {icon}
      <Typography variant="h6">{children}</Typography>
    </Stack>
  </>
);

const IfIsReplacement = ({ children }: { children: ReactNode }) => (
  <WithRecord
    render={(intervention) => {
      if (intervention.type !== InterventionType.REPLACEMENT) {
        return null;
      }
      return children;
    }}
  />
);

const OtherInterventionsList = ({
  reference,
  source,
  target,
}: {
  reference: "housings" | "devices";
  source: "housingId" | "deviceId" | "replacedDeviceId";
  target: "housingId" | "deviceId";
}) => {
  const record = useRecordContext<Intervention>();
  return (
    <ReferenceField reference={reference} source={source} link="show">
      <ReferenceManyField
        reference="interventions"
        sort={{ field: "datetime", order: "DESC" }}
        target={target}
      >
        <Datagrid
          bulkActionButtons={false}
          rowSx={(row) => {
            return record && row.id === record.id
              ? { backgroundColor: "#fff9e5" }
              : {};
          }}
        >
          <DateField label="Date" source="datetime" showTime />
          <InterventionTypeField label="Type" />
          <InterventionStatusField label="Status" />
          <InterventionSourcingStatusField label="Sourcing Status" />
          <InterventionStatusReasonField label="Status Reason" />
          {reference === "housings" ? null : (
            <ReferenceField reference="housings" source="housingId" link="show">
              <TextField source="unitId" />
            </ReferenceField>
          )}
          {reference === "devices" ? null : (
            <ReferenceField reference="devices" source="deviceId" link="show">
              <TextField source="name" />
            </ReferenceField>
          )}
          <ReferenceField
            label="Project"
            source="projectId"
            reference="projects"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField label="Comment" source="comment" />
          <WithRecord
            render={(row) =>
              record && row.id === record.id ? null : <ShowButton />
            }
          />
        </Datagrid>
      </ReferenceManyField>
    </ReferenceField>
  );
};
