import {
  AutocompleteInput,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin";
import { OptimizedReferenceOneField } from "../../../fields/OptimizedReferenceOneField";
import { NameField } from "../../end-users/fields/NameField";
import { AddressField } from "../fields/Address";
import { EquipedField } from "../fields/EquipedDate";
import { HousingLastProjectField } from "../fields/HousingLastProjectField";
import { HousingStatusField } from "../fields/Status";
import { HousingStatus, housingStatusLabels } from "../types";
import { HousingListActions } from "./actions";

export const HousingList = () => {
  return (
    <List
      perPage={25}
      actions={<HousingListActions />}
      filters={housingFilters}
    >
      <HousingDatagrid />
    </List>
  );
};

export const HousingDatagrid = () => (
  <Datagrid bulkActionButtons={false}>
    <TextField label="Unit ID" source="unitId" />
    <HousingStatusField label="Status" source="status" />
    <AddressField label="Address" />
    <HousingLastProjectField label="Last project" />
    <ReferenceField
      reference="residences"
      source="residenceId"
      label="Organization"
    >
      <ReferenceField
        reference="organizations"
        source="organizationId"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
    </ReferenceField>

    <OptimizedReferenceOneField
      reference="end-users"
      target="housingIds"
      label="End user"
      link="show"
      fields={[
        "title1",
        "title2",
        "firstName1",
        "firstName2",
        "lastName1",
        "lastName2",
      ]}
    >
      <NameField />
    </OptimizedReferenceOneField>
    <EquipedField label="Equiped Date" />
    <ReferenceField reference="residences" source="residenceId">
      <TextField source="esi" />
    </ReferenceField>
    <TextField source="pdl" />
    <DateField label="Created At" source="createdAt" />
    <DateField label="Updated At" source="updatedAt" />
    <ShowButton />
  </Datagrid>
);

const housingFilters = [
  <SearchInput source="q" alwaysOn />,
  <TextInput source="unitId" label="Unit ID" />,
  <TextInput source="Address" label="Address" />,
  <TextInput source="zipCode" label="Zip Code" />,
  <TextInput source="city" label="city" />,
  <TextInput source="pdl" label="PDL" />,
  <SelectInput
    source="status"
    label="Status"
    choices={Object.values(HousingStatus).map((value) => ({
      id: value,
      name: housingStatusLabels[value],
    }))}
    fullWidth
  />,
  <ReferenceInput
    reference="residences"
    label="Residence"
    source="residenceId"
    perPage={1000}
  >
    <AutocompleteInput optionText="esi" />
  </ReferenceInput>,
];
