import { Grid, Typography } from "@mui/material";
import {
  Button,
  BooleanInput,
  TextInput,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { getBlob, downloadFile } from "../../../providers/data";
import type { Project } from "../type";

export const DeploymentLetterForm = () => {
  const notify = useNotify();
  const project = useRecordContext<Project>();

  const isFeesEnabled = useWatch({
    name: "deploymentLetter.isFeesEnabled",
  });

  const generateLetterSample = async () => {
    try {
      const blob = await getBlob(
        `/projects/${project.id}/deployment-letter-sample`,
        "application/pdf"
      );
      downloadFile(blob, `Exemple courrier - ${project.name}.pdf`);
    } catch (error: any) {
      notify(error.toString(), {
        type: "error",
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            Automatic deployment letter
          </Typography>
        </Grid>

        <Grid item xs={6} textAlign="right">
          <Button
            onClick={generateLetterSample}
            label="Generate letter sample"
            variant="contained"
            sx={{ marginX: 4 }}
          />
        </Grid>

        <Grid item xs={12}>
          <BooleanInput
            source="isDeploymentLetterEnabled"
            label="Enable automatic sending of deployment letters"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="deploymentLetter.firstPageText"
            label="First page text"
            defaultValue={`Dans le cadre de son plan de sobriété énergétique, votre bailleur **{{LANDLORD}}** a décidé d’équiper les ballons d’eau chaude d’un thermostat connecté Elax Energie.\n\nCe thermostat améliorera votre système d'eau chaude sanitaire tout en réduisant la consommation électrique de votre chauffe-eau de 25% en moyenne, **soit une économie de 95€ par an**.`}
            multiline
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            source="deploymentLetter.appointmentText"
            label="Text above installer phone number"
            defaultValue="Scannez le QR code ou contactez notre prestataire installateur au"
            multiline
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <BooleanInput
            source="deploymentLetter.isMaintenanceProviderLogoEnabled"
            label="Display maintenance provider logo"
          />
        </Grid>

        <Grid item xs={12}>
          <BooleanInput
            source="deploymentLetter.isQRCodeEnabled"
            label="Display QR Code"
          />
        </Grid>

        <Grid item xs={12}>
          <BooleanInput
            source="deploymentLetter.isFeesEnabled"
            label="Enable second page (FES)"
          />
        </Grid>

        {isFeesEnabled && (
          <Grid item xs={12}>
            <TextInput
              source="deploymentLetter.secondPageText"
              label="Second page text"
              defaultValue={`- **{{LANDLORD}} prend intégralement en charge les frais d'installation** du dispositif et les coûts d’entretien et de service la première année.\n- **Elax Energie s’engage à vous faire faire des économies**. Né de la volonté d’agir pour la transition énergétique dans le secteur du logement social. Elax Energie facture des frais d’entretien et de services de 38,50€/an **uniquement auprès des foyers ayant fait des économies supérieures à ce montant**.\nVous aurez accès à une application pour suivre vos économies.\n\nNous pouvons proposer ce modèle gagnant / gagnant car nous allons aider le réseau électrique, grâce à l’effacement, en déplaçant certains cycles de chauffe de votre ballon lorsque la production de l’électricité est plus verte.`}
              multiline
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
