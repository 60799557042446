import { Box } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { useRecordContext } from "react-admin";
import type { EndUser } from "../type";

export function NPSField() {
  const record = useRecordContext<EndUser>();

  const color =
    record?.nps === undefined
      ? grey
      : record?.nps > 8
      ? green
      : record?.nps > 6
      ? grey
      : record?.nps <= 6
      ? red
      : grey;

  return (
    <Box
      sx={{
        backgroundColor: color["50"],
        color: color["400"],
        borderRadius: 1,
        textAlign: "center",
        fontWeight: "bold",
        paddingX: 1,
        paddingY: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "min-content",
        whiteSpace: "nowrap",
        marginY: 0.2,
      }}
    >
      {record?.nps ?? "-"}
    </Box>
  );
}
