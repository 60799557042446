import { Box, Tooltip } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import dayjs from "dayjs";
import { get } from "lodash";
import { useRecordContext } from "react-admin";

interface ConsentFieldProps {
  source: string;
  label: string;
}

export function ConsentField({ source, label }: ConsentFieldProps) {
  const record = useRecordContext();
  const value = get(record, source);

  const color =
    value?.consent === true ? green : value?.consent === false ? red : grey;

  return (
    <Tooltip
      title={
        value
          ? `Consentement collecté le ${dayjs(value.date).format(
              "DD/MM/YYYY"
            )} via ${value.source}`
          : `Consentement non collecté`
      }
    >
      <Box
        sx={{
          backgroundColor: color["50"],
          color: color["400"],
          borderRadius: 1,
          textAlign: "center",
          fontWeight: "bold",
          paddingX: 1,
          paddingY: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "min-content",
          whiteSpace: "nowrap",
          marginY: 0.2,
        }}
      >
        {label}
      </Box>
    </Tooltip>
  );
}
