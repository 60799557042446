import {
  EmailOutlined,
  History,
  Phone,
  HowToReg,
  ThumbUp,
  Today,
  EditNote,
} from "@mui/icons-material";
import { Grid, Pagination, Typography } from "@mui/material";
import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  ShowButton,
  TextField,
} from "react-admin";
import { FieldItem } from "../../../../display/FieldItem";
import { DialogButton } from "../../../../layout/DialogButton";
import { DeviceConfigPersonaIcon } from "../../../device-config-persona";
import { DataTab } from "../../../devices/show/tabs/data";
import { HousingLastProjectField } from "../../../housing/fields/HousingLastProjectField";
import { InterventionStatusField } from "../../../intervention/fields/status";
import { InterventionTypeField } from "../../../intervention/fields/type";
import { ConsentField } from "../../fields/ConsentField";
import { ContactSourceField } from "../../fields/ContactSourceField";
import { ContactValidationStatusField } from "../../fields/ContactValidationStatusField";
import { NPSField } from "../../fields/NPSField";

export function EndUserShowTab() {
  return (
    <Grid container spacing={2} margin={2}>
      <Grid item xs={12} md={6}>
        <FieldItem
          label="Persona"
          icon={<DeviceConfigPersonaIcon />}
          history={
            <ReferenceManyField
              reference="end-users-persona-change-history"
              target="endUserId"
              label={false}
              sort={{ field: "createdAt", order: "DESC" }}
              pagination={<Pagination />}
            >
              <Datagrid bulkActionButtons={false}>
                <DateField label="Date" source="createdAt" showTime />
                <ReferenceField
                  label="Persona"
                  reference="device-config-personas"
                  source="deviceConfigPersonaId"
                  link="show"
                  emptyText="None"
                />
                <TextField source="changedVia" />
                <ReferenceField
                  label="Changed by"
                  reference="users"
                  source="changedBy"
                  link="show"
                />
              </Datagrid>
            </ReferenceManyField>
          }
        >
          <ReferenceField
            reference="device-config-personas"
            source="deviceConfigPersona"
          />
        </FieldItem>
        <FieldItem label="NPS" icon={<ThumbUp />}>
          <NPSField />
        </FieldItem>
        <FieldItem label="Creation Date" icon={<Today />}>
          <DateField source="createdAt" showTime />
        </FieldItem>
        <FieldItem label="Notes" icon={<EditNote />}>
          <TextField source="notes" emptyText="-" />
        </FieldItem>
        <FieldItem label="Consents" icon={<HowToReg />}>
          <>
            {[
              {
                label: "Terms of Service",
                source: "consents.TERMS_OF_SERVICES",
              },
              {
                label: "Device Data Collection",
                source: "consents.DEVICE_DATA_COLLECTION",
              },
              {
                label: "Flex",
                source: "consents.DEMAND_RESPONSE",
              },
              {
                label: "Electricity Data Collection",
                source: "consents.ELECTRICITY_DATA_COLLECTION",
              },
            ].map(({ label, source }) => (
              <Typography variant="caption">
                <ConsentField source={source} label={label} />
              </Typography>
            ))}
          </>
        </FieldItem>
      </Grid>
      <Grid item xs={12} md={6}>
        <ArrayField source="contacts.emails.all">
          <Typography
            variant="subtitle1"
            mt={1}
            display="flex"
            alignItems="center"
          >
            <EmailOutlined sx={{ marginRight: 1 }} color="primary" /> Emails
          </Typography>
          <Datagrid
            bulkActionButtons={false}
            expand={<ContactHistory />}
            size="small"
          >
            <TextField source="emailAddress" label="Email" />
            <ContactSourceField label="Source" />
            <DateField source="createdAt" showTime label="Date" />
          </Datagrid>
        </ArrayField>
        <ArrayField source="contacts.phoneNumbers.all">
          <Typography
            variant="subtitle1"
            mt={1}
            display="flex"
            alignItems="center"
            marginTop={2}
          >
            <Phone sx={{ marginRight: 1 }} color="primary" /> Phone Numbers
          </Typography>
          <Datagrid
            bulkActionButtons={false}
            expand={<ContactHistory />}
            size="small"
          >
            <TextField source="number" label="Number" />
            <ContactSourceField label="Source" />
            <DateField source="createdAt" showTime label="Date" />
          </Datagrid>
        </ArrayField>
      </Grid>
      <Grid item xs={12}>
        <ReferenceArrayField
          reference="housings"
          source="housingIds"
          sort={{ field: "createdAt", order: "ASC" }}
          emptyText={
            '⚠️ No current housing. This end-user will be deleted after one year. History is available in the "Housing history" tab'
          }
        >
          <Typography variant="h6">
            Housings{" "}
            <DialogButton startIcon={<History />} title="History">
              <ArrayField source="housingHistory" emptyText="-">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="type" label="Type" />
                  <DateField source="date" showTime />
                  <ReferenceField
                    source="housingId"
                    reference="housings"
                    link="show"
                  >
                    <TextField source="unitId" />
                  </ReferenceField>
                </Datagrid>
              </ArrayField>
            </DialogButton>
          </Typography>
          <Datagrid
            bulkActionButtons={false}
            expand={
              <>
                <ReferenceManyField
                  reference="interventions"
                  target="housingId"
                  sort={{ field: "datetime", order: "ASC" }}
                  fullWidth
                >
                  <Typography variant="h6">Interventions</Typography>
                  <Datagrid bulkActionButtons={false}>
                    <InterventionTypeField />
                    <InterventionStatusField />
                    <DateField label="Date" source="datetime" showTime />
                    <ShowButton />
                  </Datagrid>
                </ReferenceManyField>
                <ReferenceManyField
                  reference="devices"
                  target="housingId"
                  sort={{ field: "datetime", order: "ASC" }}
                  fullWidth
                >
                  <Typography variant="h6">Devices</Typography>
                  <Datagrid bulkActionButtons={false} expand={<DataTab />}>
                    <TextField source="name" />
                    <ShowButton />
                  </Datagrid>
                </ReferenceManyField>
              </>
            }
          >
            <TextField source="unitId" label="Unit ID" emptyText="-" />
            <TextField source="address" label="Address" emptyText="-" />
            <HousingLastProjectField label="Last project" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField>
      </Grid>
    </Grid>
  );
}

const ContactHistory = () => {
  return (
    <ArrayField source="validationHistory">
      <Datagrid
        bulkActionButtons={false}
        sx={{
          borderLeft: "1px dotted #0f5ef6",
        }}
      >
        <DateField label="Date" source="date" showTime />
        <ContactValidationStatusField label="Validation Status" />
        <ContactSourceField label="Source" />
      </Datagrid>
    </ArrayField>
  );
};
